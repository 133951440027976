import { addMonths, format } from 'date-fns'
import { range } from './array'
import {
  defaultFilterValue,
  lgmDateFormatFns,
  lrpDateFormat,
} from './constants'
import { dateFormatter } from './formatters'
import { getFirstKeys } from './object'
import { makeFilterOptionValues } from './options'

export const targetWeightOptionRanges = {
  Swine: {
    min: 1.4,
    max: 2.6,
  },
  'Feeder Cattle': {
    low: {
      min: 1,
      max: 5.99,
    },
    high: {
      to9: {
        min: 6,
        max: 9,
      },
      to10: {
        min: 6,
        max: 10,
      },
    },
  },
  'Fed Cattle': {
    min: 10,
    max: 16,
  },
}

export const commodityTypesOrderedOptions = {
  'Fed Cattle': {
    'Steers & Heifers': 1,
  },
  'Feeder Cattle': {
    'Steers, 600-1000 lbs': 1,
    'Steers, <600 lbs': 2,
    'Heifers, 600-1000 lbs': 3,
    'Heifers, <600 lbs': 4,
    'Brahman, 600-1000 lbs': 5,
    'Brahman, <600 lbs': 6,
    'Dairy, 600-1000 lbs': 7,
    'Dairy, <600 lbs': 8,
    'Unborn Steers & Heifers': 9,
    'Unborn Brahman': 10,
    'Unborn Dairy': 11,
  },
  Swine: {
    'No Type Specified': 1,
    'Unborn Swine': 2,
  },
}

export const validLrpCommodityTypeCodes = [
  809, 810, 811, 812, 813, 814, 815, 816, 817, 818, 819, 820, 821, 997,
]

export const [defaultCommodity, defaultCommodityType] = getFirstKeys(
  commodityTypesOrderedOptions,
)

export const parseCommodityMarketDates = commodityMarketDates =>
  Object.entries(commodityMarketDates).reduce(
    (acc, [commodity, marketDatesByCommodityType]) => {
      acc.commodities.push(commodity)
      acc.commodityTypesMap[commodity] = Object.keys(marketDatesByCommodityType)
      acc.marketDatesByCommodityType = Object.assign(
        acc.marketDatesByCommodityType,
        marketDatesByCommodityType,
      )

      return acc
    },
    {
      commodities: [],
      commodityTypesMap: {},
      marketDatesByCommodityType: {},
    },
  )

const generateTargetWeightProps = (min, max) => ({
  min,
  max,
  helperText: `Allowed range: ${min} to ${max}`,
})

const targetWeightOptions = {
  Swine: generateTargetWeightProps(
    targetWeightOptionRanges.Swine.min,
    targetWeightOptionRanges.Swine.max,
  ),
  'Feeder Cattle': {
    low: generateTargetWeightProps(
      targetWeightOptionRanges['Feeder Cattle'].low.min,
      targetWeightOptionRanges['Feeder Cattle'].low.max,
    ),
    high: {
      to9: generateTargetWeightProps(
        targetWeightOptionRanges['Feeder Cattle'].high.to9.min,
        targetWeightOptionRanges['Feeder Cattle'].high.to9.max,
      ),
      to10: generateTargetWeightProps(
        targetWeightOptionRanges['Feeder Cattle'].high.to10.min,
        targetWeightOptionRanges['Feeder Cattle'].high.to10.max,
      ),
    },
  },
  'Fed Cattle': generateTargetWeightProps(
    targetWeightOptionRanges['Fed Cattle'].min,
    targetWeightOptionRanges['Fed Cattle'].max,
  ),
}

// DIE
export const getTargetWeightProps = (commodity, commodityType) => {
  let weightRange = 'low'
  let weightRangeVariation
  if (commodityType?.endsWith('-1000 lbs')) {
    weightRange = 'high'
    weightRangeVariation = 'to10'
  }
  if (commodityType?.endsWith('-900 lbs')) {
    weightRange = 'high'
    weightRangeVariation = 'to9'
  }
  return (
    targetWeightOptions[commodity]?.[weightRange]?.[weightRangeVariation] ??
    targetWeightOptions[commodity]?.[weightRange] ??
    targetWeightOptions[commodity]
  )
}

// for internal use, non-display
export const COMMODITY_CATTLE = 'cattle'
export const COMMODITY_SWINE = 'swine'

export const lgmCattleOptions = ['Calf Finishing', 'Yearling Finishing']
export const lgmSwineOptions = [
  'Finishing Operation',
  'SEW Operation',
  'Farrow to Finish',
]

export const getCommodityFromFinishingType = finishingType => {
  return lgmCattleOptions.find(item => item === finishingType)
    ? COMMODITY_CATTLE
    : COMMODITY_SWINE
}

export const cattleOptions = lgmCattleOptions.map(
  option => `Cattle - ${option}`,
)
const swineOptions = lgmSwineOptions.map(option => `Swine - ${option}`)

export const commodityTypeOptions = [...cattleOptions, ...swineOptions]

export const getMarks = (max, min, step) =>
  range(max, min, step).map(val => ({ label: val, value: val }))

const commodityMap = [
  ['Cattle', cattleOptions],
  ['Swine', swineOptions],
].reduce((acc, [commodity, options]) => {
  options.forEach(option => {
    acc[option] = commodity
  })
  return acc
}, {})

export const commodityFinishingTypeMap = [
  ['Cattle', lgmCattleOptions],
  ['Swine', lgmSwineOptions],
].reduce((acc, [commodity, options]) => {
  options.forEach(option => {
    acc[option] = commodity
  })
  return acc
}, {})

export const finishingTypeOptionMap = [
  ...cattleOptions,
  ...swineOptions,
].reduce((acc, crr) => {
  const [_, val] = crr.split(' - ')
  acc[crr] = val
  return acc
}, {})

export const getCommodity = commodityType => commodityMap[commodityType]

export const makeCoverageCommodityLabel = (commodity, commodityType) =>
  `${commodity} - ${commodityType}`

const commoditiesOrder = Object.keys(commodityTypesOrderedOptions)

const findCommodityIndex = commodity =>
  commoditiesOrder.findIndex(comm => comm === commodity)

export const orderCommodities = commodities => {
  return commodities.sort(
    (a, b) => findCommodityIndex(a) - findCommodityIndex(b),
  )
}

export const orderCommodityTypes = commodityTypesMap => {
  const ordered = {}
  orderCommodities(Object.keys(commodityTypesMap)).forEach(commodity => {
    const commodityTypes = commodityTypesOrderedOptions[commodity]
    ordered[commodity] = commodityTypesMap[commodity].sort(
      (a, b) => commodityTypes[a] - commodityTypes[b],
    )
  })
  return ordered
}

export const lrpCommodityTypeOptions = Object.entries(
  commodityTypesOrderedOptions,
).reduce(
  (acc, [commodity, options]) => {
    Object.keys(options).forEach(option => {
      acc.push(makeCoverageCommodityLabel(commodity, option))
    })
    return acc
  },
  [defaultFilterValue],
)

export const lgmCommodityTypeOptions =
  makeFilterOptionValues(commodityTypeOptions)

export const commoditiesMap = {
  lrp: {
    fedCattle: 'Fed Cattle',
    feederCattle: 'Feeder Cattle',
    swine: 'Swine',
  },
  lgm: {
    [COMMODITY_CATTLE]: 'Cattle',
    [COMMODITY_SWINE]: 'Swine',
  },
}

export const commodityTypesByCommodity = {
  lrp: {
    fedCattle: ['Steers & Heifers'],
    feederCattle: [
      'Steers, 600-1000 lbs',
      'Steers, <600 lbs',
      'Heifers, 600-1000 lbs',
      'Heifers, <600 lbs',
      'Brahman, 600-1000 lbs',
      'Brahman, <600 lbs',
      'Dairy, 600-1000 lbs',
      'Dairy, <600 lbs',
      'Unborn Steers & Heifers',
      'Unborn Brahman',
      'Unborn Dairy',
    ],
    swine: ['No Type Specified', 'Unborn Swine'],
  },
}

export const defaultEndorsementLengthByCommodity = {
  [commoditiesMap.lrp.feederCattle]: 13,
  [commoditiesMap.lrp.fedCattle]: 26,
  [commoditiesMap.lrp.swine]: 13,
}

export const numberOfHeadParameters = {
  limit: {
    Swine: 70000,
    'Fed Cattle': 12000,
    'Feeder Cattle': 12000,
  },
  helperText: {
    Swine: 'Swine max limit is 70,000',
    'Fed Cattle': 'Fed Cattle max limit is 12,000',
    'Feeder Cattle': 'Feeder Cattle max limit is 12,000',
  },
}

export const getMarketingMonthsQuery = marketingMonths =>
  marketingMonths.map(([marketingMonth, numberofHead]) => {
    return {
      marketingMonth: dateFormatter(marketingMonth, lrpDateFormat),
      numberofHead,
    }
  })

export const commodityTypeOptionsByCommodity = {
  Cattle: {
    commodities: ['Calf Finishing', 'Yearling Finishing'],
    deductible: range(0, 150, 10),
    targetMarketLength: 10,
  },
  Swine: {
    commodities: {
      'Farrow to Finish': 'Farrow to Finish',
      'Finishing Operation': 'Finishing Operation',
      'SEW Operation': 'SEW Operation',
      *[Symbol.iterator]() {
        yield* Object.keys(this)
      },
    },
    deductible: range(0, 20, 2),
    targetMarketLength: 5,
  },
}

export const getMarketingMonths = (
  commodity,
  date = new Date(),
  marketingMonths = {},
) => {
  date.setDate(1)

  const commodityTypeMaxLength =
    commodityTypeOptionsByCommodity[commodity].targetMarketLength

  const marketingMonthsMap = new Map()

  for (let i = 1; i <= commodityTypeMaxLength; i++) {
    const updatedDate = addMonths(date, i + 1)
    const formattedMonth = format(updatedDate, lgmDateFormatFns)
    marketingMonthsMap.set(formattedMonth, marketingMonths[formattedMonth] ?? 0)
  }
  return marketingMonthsMap
}

export const lrpContractMonthsTraded = {
  [commoditiesMap.lrp.fedCattle]: [
    'February',
    'April',
    'June',
    'August',
    'October',
    'December',
  ],
  [commoditiesMap.lrp.feederCattle]: [
    'January',
    'March',
    'April',
    'May',
    'August',
    'September',
    'October',
    'November',
  ],
  [commoditiesMap.lrp.swine]: [
    'February',
    'April',
    'May',
    'June',
    'July',
    'August',
    'October',
    'December',
  ],
}

export const commoditySymbolsMap = {
  [commoditiesMap.lrp.fedCattle]: 'LE',
  [commoditiesMap.lrp.feederCattle]: 'GF',
  [commoditiesMap.lrp.swine]: 'HE',
  [commoditiesMap.lgm.cattle]: 'LE',
}
