import { StyledEngineProvider } from '@mui/material/styles'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import enLocale from 'date-fns/locale/en-US'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { BrowserRouter } from 'react-router-dom'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'
import { parse, stringify } from 'qs'

import {
  AppSettingsProvider,
  ModalProvider,
  ToastProvider,
  TourProvider,
  UserProvider,
  RedirectProvider,
  ThemeManager,
} from '.'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      useErrorBoundary: true,
    },
  },
})

export function AppProvider({ children }) {
  return (
    <QueryClientProvider client={queryClient}>
      <AppSettingsProvider>
        <StyledEngineProvider injectFirst>
          <ThemeManager>
            <BrowserRouter>
              <QueryParamProvider
                adapter={ReactRouter6Adapter}
                options={{
                  searchStringToObject: queryString =>
                    parse(queryString.replace(/^\?/g, '')),
                  objectToSearchString: stringify,
                }}
              >
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={enLocale}
                >
                  <RedirectProvider>
                    <ToastProvider>
                      <UserProvider>
                        <ModalProvider>
                          <TourProvider>{children}</TourProvider>
                        </ModalProvider>
                      </UserProvider>
                    </ToastProvider>
                  </RedirectProvider>
                  <ReactQueryDevtools initialIsOpen={false} />
                </LocalizationProvider>
              </QueryParamProvider>
            </BrowserRouter>
          </ThemeManager>
        </StyledEngineProvider>
      </AppSettingsProvider>
    </QueryClientProvider>
  )
}
