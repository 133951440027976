import { z } from 'zod';
import { zDecimalRequired } from '@harvestiq/zod';
import { InsureIQPolicyTypes } from '@harvestiq/constants';

export const insureIQEmailCoveragesReportRequestSchema = z.object({
  ccMe: z.boolean(),
  emailNotes: z.string(),
  s3FileKey: z.string(),
  recipients: z.array(
    z.object({
      email: z.string().email(),
      name: z.string(),
    })
  ),
  type: z.nativeEnum(InsureIQPolicyTypes),
  sendPreview: z.boolean().optional(),
});

export type InsureIQEmailCoveragesReportRequest = z.infer<
  typeof insureIQEmailCoveragesReportRequestSchema
>;

export const insureIQEmailCoveragesReportResponseSchema = z.object({
  emailNotes: z.string(),
  s3FileKey: z.string(),
  fileUrl: z.string(),
  type: z.nativeEnum(InsureIQPolicyTypes),
  insured: z.string(),
});

export type InsureIQEmailCoveragesReportResponse = z.infer<
  typeof insureIQEmailCoveragesReportResponseSchema
>;

export const coveragesDetailedWorksheetSchema = z.object({
  commodity: z.string(),
  numberofHead: z.number(),
  targetWeight: z.number(),
  salesEffectiveDate: z.string(),
  endDate: z.string(),
  totalCoveragePrice: z.number(),
  totalPremium: z.number(),
  totalNetCoveragePrice: z.number(),
  totalProjectedOrActualRevenue: z.number().nullable(),
  totalProjectedIndemnity: z.number().nullable(),
  totalNetProjectedIndemnity: z.number().nullable(),
});

export type CoveragesDetailedWorksheet = z.infer<
  typeof coveragesDetailedWorksheetSchema
>;
export const coveragesAggregatedWorksheetSchema =
  coveragesDetailedWorksheetSchema
    .pick({
      commodity: true,
      numberofHead: true,
      totalCoveragePrice: true,
      totalPremium: true,
      totalNetCoveragePrice: true,
      totalProjectedOrActualRevenue: true,
      totalProjectedIndemnity: true,
      totalNetProjectedIndemnity: true,
    })
    .extend({
      endMonth: z.string(),
      numberOfEndorsements: z.number().optional(),
      totalTargetWeight: z.number(),
    });

export type CoveragesAggregatedWorksheet = z.infer<
  typeof coveragesAggregatedWorksheetSchema
>;

export const lrpCoveragesReportKpisSchema = z.object({
  totalEndorsements: zDecimalRequired(),
  totalHeadcount: zDecimalRequired(),
  totalWeightCwt: zDecimalRequired(),
  totalInsuredValue: zDecimalRequired(),
  totalProjectedRevenue: zDecimalRequired(),
  totalProjectedIndemnity: zDecimalRequired(),
  totalProducerPremium: zDecimalRequired(),
  totalNetProjectedIndemnity: zDecimalRequired(),
});

export type LrpCoveragesReportKpis = z.infer<
  typeof lrpCoveragesReportKpisSchema
>;

export const getLrpCoveragesReportResponseSchema = z.object({
  kpis: lrpCoveragesReportKpisSchema,
  s3FileKey: z.string(),
});
export type GetLrpCoveragesReportResponse = z.infer<
  typeof getLrpCoveragesReportResponseSchema
>;

export const lgmCoveragesReportEndorsementSchema = z.object({
  salesEffectiveDate: z.string(),
  commodity: z.string(),
  marketingMonth: z.string(),
  totalEndorsements: z.number().optional(),
  totalTargetMarketings: z.number(),
  totalExpectedGrossMargin: z.number(),
  deductible: z.number().optional(),
  totalGrossMarginGaurantee: z.number(),
  totalProbableOrActualGrossMargin: z.number(),
  totalBasePremium: z.number(),
  totalSubsidy: z.number(),
  totalProducerPremium: z.number(),
  totalGrossMarginChange: z.number(),
  totalProjectedIndemnity: z.number(),
  totalNetProjectedIndemnity: z.number(),
});
export type LgmCoveragesReportEndorsement = z.infer<
  typeof lgmCoveragesReportEndorsementSchema
>;

export const lgmCoveragesReportKpisSchema = z.object({
  totalEndorsements: zDecimalRequired(),
  totalTargetMarketings: zDecimalRequired(),
  totalExpectedGrossMargin: zDecimalRequired(),
  totalGrossMarginGaurantee: zDecimalRequired(),
  totalProbableOrActualGrossMargin: zDecimalRequired(),
  totalBasePremium: zDecimalRequired(),
  totalSubsidy: zDecimalRequired(),
  totalProducerPremium: zDecimalRequired(),
  totalProjectedIndemnity: zDecimalRequired(),
  totalNetProjectedIndemnity: zDecimalRequired(),
});

export type LgmCoveragesReportKpis = z.infer<
  typeof lgmCoveragesReportKpisSchema
>;

export const lgmCoveragesReportMonthlySchema =
  lgmCoveragesReportEndorsementSchema.pick({
    marketingMonth: true,
    commodity: true,
    totalTargetMarketings: true,
    totalEndorsements: true,
    totalProducerPremium: true,
    totalProjectedIndemnity: true,
    totalNetProjectedIndemnity: true,
  });

export type LgmCoveragesReportMonthly = z.infer<
  typeof lgmCoveragesReportMonthlySchema
>;

export const getLgmCoveragesReportResponseSchema = z.object({
  kpis: lgmCoveragesReportKpisSchema,
  s3FileKey: z.string(),
});
export type GetLgmCoveragesReportResponse = z.infer<
  typeof getLgmCoveragesReportResponseSchema
>;
