import { z } from 'zod';
import { AipIds } from '@harvestiq/constants';
import { AipEventTypes } from '../aip-event/aip-event-types';
import { policyProducerEventReqSchema } from './policy-producer-event-req';

export const policyProducerEventMsgSchema = z.object({
  eventType: z.literal(AipEventTypes.policyProducerUpdate),
  orgId: z.string(),
  aip: z.nativeEnum(AipIds),
  request: policyProducerEventReqSchema,
});

export type PolicyProducerEventMsg = z.infer<
  typeof policyProducerEventMsgSchema
>;
