import { AipIds } from '@harvestiq/constants';
import { z } from 'zod';
import { AipEventTypes } from '../aip-event/aip-event-types';
import {
  createFmhLgmEndorsementSchema,
  createFmhLrpEndorsementSchema,
} from '@harvestiq/external-clients/fmh/common';
import { lgmDetailsMarketingMonthsSchema } from '@harvestiq/iiq/common';

const sharedSubmitAipCoverageReqSchema = z.object({
  orgId: z.string(),
  orgSchema: z.string(),
  aip: z.nativeEnum(AipIds),
  coverageId: z.string().uuid(),
  insuredUserId: z.string().uuid(),
  agentUserId: z.string().uuid(),
  reinsuranceYear: z.number().int(),
  aipPolicyProducerKey: z.string(),
  aipInsuranceAgentKey: z.string(),
  aipInsuranceInForceKey: z.string(),
});

const submitAipLrpCoverageReqSchema = sharedSubmitAipCoverageReqSchema.extend({
  endorsementRequest: createFmhLrpEndorsementSchema,
  coverageLevel: z.number(),
});
export const submitAipLrpCoverageMsgSchema = z.object({
  eventType: z.literal(AipEventTypes.submitAipLrpCoverage),
  orgId: z.string(),
  aip: z.nativeEnum(AipIds),
  request: submitAipLrpCoverageReqSchema,
});
export type SubmitAipLrpCoverageMsg = z.infer<
  typeof submitAipLrpCoverageMsgSchema
>;

const submitAipLgmCoverageReqSchema = sharedSubmitAipCoverageReqSchema.extend({
  endorsementRequest: createFmhLgmEndorsementSchema,
  marketingMonths: z.array(lgmDetailsMarketingMonthsSchema),
});
export const submitAipLgmCoverageMsgSchema = z.object({
  eventType: z.literal(AipEventTypes.submitAipLgmCoverage),
  orgId: z.string(),
  aip: z.nativeEnum(AipIds),
  request: submitAipLgmCoverageReqSchema,
});
export type SubmitAipLgmCoverageMsg = z.infer<
  typeof submitAipLgmCoverageMsgSchema
>;
