import { subDays, differenceInWeeks, isValid, format } from 'date-fns'
import { zonedDate, parseMarketingMonth } from './date.js'
import {
  dateFormat,
  defaultDateTimeFormat,
  defaultTimeZone,
  gmt0timezone,
  gmt0timezone as timezone,
} from './constants.js'

const defaultDateFormatterOptions = {
  timeZone: defaultTimeZone,
}

export const dateFormatter = (date, formatStr = dateFormat, options = {}) => {
  const newDate = isValid(new Date(date)) ? date : parseMarketingMonth(date) // MMM yyyy is not valid date for default Date library

  return format(zonedDate(newDate), formatStr, {
    ...defaultDateFormatterOptions,
    ...options,
  })
}

export const formatTradeDateTime = date =>
  format(zonedDate(date, gmt0timezone), defaultDateTimeFormat)

export const formatDateRange = (startDate, endDate, formatStr = dateFormat) =>
  `${format(zonedDate(startDate), formatStr)} - ${format(
    zonedDate(endDate),
    formatStr,
  )}`

// no rounding
export const formatDecimals = (number, limit = 2) => {
  const value = number.toString()
  return value.slice(0, value.indexOf('.') + 1 + limit)
}

export const limitDecimals = (val, limit = 2) => {
  const numStr = String(val)
  const index = numStr.indexOf('.')
  if (index >= 0) {
    return parseFloat(numStr.slice(0, index + limit + 1))
  }
  return val
}

export const getFormattedFloat = (val, limit = 2) =>
  +limitDecimals(parseFloat(val), limit)

export const formatCurrency = (v, options = {}) =>
  getFormattedFloat(v).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    ...options,
  })

export const formatPercent = (v, digits = 2) => `${(+v * 100).toFixed(digits)}%`

const formatterMap = {
  currency: formatCurrency,
  percent: formatPercent,
  date: dateFormatter,
}

export const getFormatter =
  (key = 'percent') =>
  (...args) =>
    formatterMap[key](...args)

export const getMark = val => ({
  label: getFormattedFloat(val).toFixed(2),
  value: getFormattedFloat(val),
})

export const formatString = (str, ...args) => `${str}`?.replace(...args)

export const formatPhone = phone =>
  formatString(phone, /^(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')

export const formatSSN = ssn =>
  formatString(ssn, /^(\d{3})(\d{2})(\d{3})/, '$1-$2-$3')

export const formatEIN = ein => formatString(ein, /^(\d{2})(\d{7})/, '$1-$2')

export const tickFormatter = val =>
  typeof val === 'number'
    ? formatCurrency(val, { maximumFractionDigits: 0 })
    : ''
