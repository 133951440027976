import { z } from 'zod';

import { dbLrpEndorsementSchema } from './db-lrp-endorsement';
import { dbLgmEndorsementSchema } from './db-lgm-endorsement';
import { safeDbUserSchema } from '../users/db-user';

export const getCoverageResponseSchema = z.object({
  coverage: z.discriminatedUnion('type', [
    dbLrpEndorsementSchema.extend({
      userOwner: safeDbUserSchema.nullable(),
    }),
    dbLgmEndorsementSchema.extend({
      userOwner: safeDbUserSchema.nullable(),
    }),
  ]),
});
export type GetCoverageResponse = z.infer<typeof getCoverageResponseSchema>;
