import { createContext, useContext } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useMediaQuery } from 'react-responsive'

import { orgAppSettingsDefaults, mergeOrgAppSettings } from 'common'
import BaseLoading from '../../components/Loading/BaseLoading'
import { getSettings } from '../../http'

const AppSettingsContext = createContext({})
const { Provider } = AppSettingsContext

const useAppSettingsProvider = () => {
  const queryKey = 'getSettings'

  const {
    data: { appSettings = orgAppSettingsDefaults, ...restSettings } = {},
    refetch,
    isLoading,
  } = useQuery(
    [queryKey],
    async () => {
      const response = await getSettings()

      if (!response) {
        console.error(
          'Unexpected error when fetching settings. No axios response.', // @note: there is a try/catch now in the BE, soon we may remove this
        )
        return {}
      }

      return response.data
    },
    {
      staleTime: Infinity,
      retry: 0,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  )

  const isMobile = useMediaQuery({ query: '(max-width: 770px)' })

  return {
    appSettings: mergeOrgAppSettings(appSettings),
    isLoaded: !isLoading,
    isMobile,
    refetch,
    orgAppSettingsDefaults,
    mergeOrgAppSettings,
    ...restSettings,
  }
}

export const AppSettingsProvider = ({ children }) => {
  const appSettings = useAppSettingsProvider()

  return (
    <Provider value={appSettings}>
      {appSettings.isLoaded ? children : <BaseLoading />}
    </Provider>
  )
}

export const useAppSettings = () => useContext(AppSettingsContext)
