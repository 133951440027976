import { z } from 'zod';
import { AipEventTypes } from '../aip-event/aip-event-types';
import { AipIds } from '@harvestiq/constants';
import { syncAipReqSchema } from './sync-aip-req';

export const syncAipMsgSchema = z.object({
  eventType: z.literal(AipEventTypes.syncAip),
  orgId: z.string(),
  aip: z.nativeEnum(AipIds),
  request: syncAipReqSchema,
});
export type SyncAipMsg = z.infer<typeof syncAipMsgSchema>;
