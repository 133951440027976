import { z } from 'zod';
import { userCompanyResponseSchema } from '../companies/db-companies';
import { userAgentResponseSchema } from '../agents/db-agent';

// @todo double-check why it is undefined/null for some props: capabilities, createdAt, updatedAt, deletedAt and User_Role
const roleSchema = z.object({
  id: z.string(),
  title: z.string(),
  description: z.string().nullable(),
  capabilities: z.array(z.string()).optional().nullable(),
  createdAt: z.string().datetime().optional(),
  updatedAt: z.string().datetime().optional(),
  deletedAt: z.string().datetime().optional().nullable(),
  User_Role: z
    .object({
      id: z.string(),
      userId: z.string(),
      roleId: z.string(),
      createdAt: z.string().datetime(),
      updatedAt: z.string().datetime(),
    })
    .optional(),
});

export const phoneSchema = z.object({
  type: z.string(),
  number: z.string(),
  isPrimary: z.boolean(),
});

export const profileSchema = z.object({
  ssn: z.string().nullable(),
  primaryPhone: z.string().optional(),
  id: z.string(),
  userId: z.string(),
  maritalStatus: z.string().nullable(),
  liveStock: z.string().nullable(),
  farmerType: z.string().nullable(),
  dob: z.string().datetime().nullable(),
  phones: z.array(phoneSchema),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
  deletedAt: z.string().datetime().nullable(),
});

export const eventSchema = z.object({
  createdAt: z.string().datetime(),
  entityId: z.string(),
  entityType: z.string(),
  id: z.string(),
  name: z.string(),
  updatedAt: z.string().datetime(),
  value: z.any().nullable(),
});

export const userSchema = z.object({
  isConfirmed: z.boolean(),
  isDisabled: z.boolean(),
  fullName: z.string(),
  id: z.string(),
  firstName: z.string(),
  middleName: z.string().nullable(),
  lastName: z.string(),
  email: z.string().email(),
  confirmedAt: z.string().datetime().nullable(),
  suffix: z.string().nullable(),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
  deletedAt: z.string().datetime().nullable(),
  roles: z.array(roleSchema),
  profile: profileSchema,
  // @note legacy cookie props
  events: z.array(eventSchema),
  timezone: z.string().nullable(),
  agents: z.array(userAgentResponseSchema),
  avatar: z.string().url().optional(),
  avatarUrl: z.string().url().optional(),
  companies: z.array(userCompanyResponseSchema),
});

export type User = z.infer<typeof userSchema>;
