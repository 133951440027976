import { z } from 'zod';
import { dbPolicySchema } from './db-policy';
import { dbLocationSchema } from '../locations/db-locations';
import { add } from 'lodash';

export const getPoliciesRequestSchema = z.object({
  entityIds: z.array(z.string().uuid()).optional(),
  skip: z.coerce.number().int().nonnegative().optional(),
  limit: z.coerce.number().int().positive().optional(),
});

export type GetPoliciesRequest = z.infer<typeof getPoliciesRequestSchema>;

const legacyPolicyMailingAddress = dbLocationSchema
  .pick({
    city: true,
    county: true,
    state: true,
    zip: true,
    careOfName: true,
    country: true,
    addressType: true,
    coordinates: true,
  })
  .extend({
    addressLine1: z.string().nullable(),
    addressLine2: z.string().nullable(),
  });

export const legacyMergedPolicy = dbPolicySchema
  .merge(legacyPolicyMailingAddress.partial())
  .extend({
    livestockLocation: dbLocationSchema
      .pick({
        state: true,
        county: true,
        zip: true,
      })
      .optional(),
    application: z.string().nullable(),
    agentId: z.string().nullable(),
  });

export type LegacyMergedPolicy = z.infer<typeof legacyMergedPolicy>;

export const getPoliciesResponseSchema = z.object({
  policies: z.array(legacyMergedPolicy),
  totalCount: z.number(),
});

export type GetPoliciesResponse = z.infer<typeof getPoliciesResponseSchema>;
