import { z } from 'zod';
import { dbEntitySchema } from './db-entity';

export const searchEntitiesRequestSchema = z.object({
  searchText: z.string().optional(),
  skip: z.coerce.number().int().nonnegative().optional(),
  limit: z.coerce.number().int().positive().optional(),
});

export type SearchEntitiesRequest = z.infer<typeof searchEntitiesRequestSchema>;

export const searchEntitiesResponseSchema = z.object({
  entities: z.array(dbEntitySchema),
  totalCount: z.number(),
});

export type SearchEntitiesResponse = z.infer<
  typeof searchEntitiesResponseSchema
>;
