import { z } from 'zod';
import {
  lgmCoverageTableSchema,
  lrpCoverageTableSchema,
} from '@harvestiq/iiq/common';

const commonSchema = {
  agentMgmtLink: z.string(),
};

export const submitAipCoverageFailureDataSchema = z.discriminatedUnion('type', [
  lrpCoverageTableSchema.extend(commonSchema),
  lgmCoverageTableSchema.extend(commonSchema),
]);
export type SubmitAipCoverageFailureData = z.infer<
  typeof submitAipCoverageFailureDataSchema
>;
